export const countryCodes = [
    {
        id: 'TH',
        country: 'Thailand',
        code: '+66',
        value: '66'
    },
    {
        id: 'US',
        country: 'United States',
        code: '+1',
        value: '1'
    },
    {
        id: 'AF',
        country: 'Afghanistan',
        code: '+93',
        value: '93'
    },
    {
        id: 'AL',
        country: 'Albania',
        code: '+355',
        value: '355'
    },
    {
        id: 'DZ',
        country: 'Algeria',
        code: '+213',
        value: '213'
    },
    {
        id: 'AS',
        country: 'American Samoa',
        code: '+1-684',
        value: '1-684'
    },
    {
        id: 'AD',
        country: 'Andorra',
        code: '+376',
        value: '376'
    },
    {
        id: 'AO',
        country: 'Angola',
        code: '+244',
        value: '244'
    },
    {
        id: 'AI',
        country: 'Anguilla',
        code: '+1-264',
        value: '1-264'
    },
    {
        id: 'AQ',
        country: 'Antarctica',
        code: '+672',
        value: '672'
    },
    {
        id: 'AG',
        country: 'Antigua and Barbuda',
        code: '+1-268',
        value: '1-268'
    },
    {
        id: 'AR',
        country: 'Argentina',
        code: '+54',
        value: '54'
    },
    {
        id: 'AM',
        country: 'Armenia',
        code: '+374',
        value: '374'
    },
    {
        id: 'AW',
        country: 'Aruba',
        code: '+297',
        value: '297'
    },
    {
        id: 'AU',
        country: 'Australia',
        code: '+61',
        value: '61'
    },
    {
        id: 'AT',
        country: 'Austria',
        code: '+43',
        value: '43'
    },
    {
        id: 'AZ',
        country: 'Azerbaijan',
        code: '+994',
        value: '994'
    },
    {
        id: 'BS',
        country: 'Bahamas',
        code: '+1-242',
        value: '1-242'
    },
    {
        id: 'BH',
        country: 'Bahrain',
        code: '+973',
        value: '973'
    },
    {
        id: 'BD',
        country: 'Bangladesh',
        code: '+880',
        value: '880'
    },
    {
        id: 'BB',
        country: 'Barbados',
        code: '+1-246',
        value: '1-246'
    },
    {
        id: 'BY',
        country: 'Belarus',
        code: '+375',
        value: '375'
    },
    {
        id: 'BE',
        country: 'Belgium',
        code: '+32',
        value: '32'
    },
    {
        id: 'BZ',
        country: 'Belize',
        code: '+501',
        value: '501'
    },
    {
        id: 'BJ',
        country: 'Benin',
        code: '+229',
        value: '229'
    },
    {
        id: 'BM',
        country: 'Bermuda',
        code: '+1-441',
        value: '1-441'
    },
    {
        id: 'BT',
        country: 'Bhutan',
        code: '+975',
        value: '975'
    },
    {
        id: 'BO',
        country: 'Bolivia',
        code: '+591',
        value: '591'
    },
    {
        id: 'BA',
        country: 'Bosnia and Herzegovina',
        code: '+387',
        value: '387'
    },
    {
        id: 'BW',
        country: 'Botswana',
        code: '+267',
        value: '267'
    },
    {
        id: 'BR',
        country: 'Brazil',
        code: '+55',
        value: '55'
    },
    {
        id: 'IO',
        country: 'British Indian Ocean Territory',
        code: '+246',
        value: '246'
    },
    {
        id: 'VG',
        country: 'British Virgin Islands',
        code: '+1-284',
        value: '1-284'
    },
    {
        id: 'BN',
        country: 'Brunei',
        code: '+673',
        value: '673'
    },
    {
        id: 'BG',
        country: 'Bulgaria',
        code: '+359',
        value: '359'
    },
    {
        id: 'BF',
        country: 'Burkina Faso',
        code: '+226',
        value: '226'
    },
    {
        id: 'MM',
        country: 'Myanmar',
        code: '+95',
        value: '95'
    },
    {
        id: 'BI',
        country: 'Burundi',
        code: '+257',
        value: '257'
    },
    {
        id: 'KH',
        country: 'Cambodia',
        code: '+855',
        value: '855'
    },
    {
        id: 'CM',
        country: 'Cameroon',
        code: '+237',
        value: '237'
    },
    {
        id: 'CA',
        country: 'Canada',
        code: '+1',
        value: '1'
    },
    {
        id: 'CV',
        country: 'Cape Verde',
        code: '+238',
        value: '238'
    },
    {
        id: 'KY',
        country: 'Cayman Islands',
        code: '+1-345',
        value: '1-345'
    },
    {
        id: 'CF',
        country: 'Central African Republic',
        code: '+236',
        value: '236'
    },
    {
        id: 'TD',
        country: 'Chad',
        code: '+235',
        value: '235'
    },
    {
        id: 'CL',
        country: 'Chile',
        code: '+56',
        value: '56'
    },
    {
        id: 'CN',
        country: 'China',
        code: '+86',
        value: '86'
    },
    {
        id: 'CX',
        country: 'Christmas Island',
        code: '+61',
        value: '61'
    },
    {
        id: 'CC',
        country: 'Cocos Islands',
        code: '+61',
        value: '61'
    },
    {
        id: 'CO',
        country: 'Colombia',
        code: '+57',
        value: '57'
    },
    {
        id: 'KM',
        country: 'Comoros',
        code: '+269',
        value: '269'
    },
    {
        id: 'CG',
        country: 'Republic of the Congo',
        code: '+242',
        value: '242'
    },
    {
        id: 'CD',
        country: 'Democratic Republic of the Congo',
        code: '+243',
        value: '243'
    },
    {
        id: 'CK',
        country: 'Cook Islands',
        code: '+682',
        value: '682'
    },
    {
        id: 'CR',
        country: 'Costa Rica',
        code: '+506',
        value: '506'
    },
    {
        id: 'HR',
        country: 'Croatia',
        code: '+385',
        value: '385'
    },
    {
        id: 'CU',
        country: 'Cuba',
        code: '+53',
        value: '53'
    },
    {
        id: 'CW',
        country: 'Curacao',
        code: '+599',
        value: '599'
    },
    {
        id: 'CY',
        country: 'Cyprus',
        code: '+357',
        value: '357'
    },
    {
        id: 'CZ',
        country: 'Czech Republic',
        code: '+420',
        value: '420'
    },
    {
        id: 'DK',
        country: 'Denmark',
        code: '+45',
        value: '45'
    },
    {
        id: 'DJ',
        country: 'Djibouti',
        code: '+253',
        value: '253'
    },
    {
        id: 'DM',
        country: 'Dominica',
        code: '+1-767',
        value: '1-767'
    },
    {
        id: 'DO',
        country: 'Dominican Republic',
        code: '+1-809, 1-829, 1-849',
        value: '1-809, 1-829, 1-849'
    },
    {
        id: 'TL',
        country: 'East Timor',
        code: '+670',
        value: '670'
    },
    {
        id: 'EC',
        country: 'Ecuador',
        code: '+593',
        value: '593'
    },
    {
        id: 'EG',
        country: 'Egypt',
        code: '+20',
        value: '20'
    },
    {
        id: 'SV',
        country: 'El Salvador',
        code: '+503',
        value: '503'
    },
    {
        id: 'GQ',
        country: 'Equatorial Guinea',
        code: '+240',
        value: '240'
    },
    {
        id: 'ER',
        country: 'Eritrea',
        code: '+291',
        value: '291'
    },
    {
        id: 'EE',
        country: 'Estonia',
        code: '+372',
        value: '372'
    },
    {
        id: 'ET',
        country: 'Ethiopia',
        code: '+251',
        value: '251'
    },
    {
        id: 'FK',
        country: 'Falkland Islands',
        code: '+500',
        value: '500'
    },
    {
        id: 'FO',
        country: 'Faroe Islands',
        code: '+298',
        value: '298'
    },
    {
        id: 'FJ',
        country: 'Fiji',
        code: '+679',
        value: '679'
    },
    {
        id: 'FI',
        country: 'Finland',
        code: '+358',
        value: '358'
    },
    {
        id: 'FR',
        country: 'France',
        code: '+33',
        value: '33'
    },
    {
        id: 'PF',
        country: 'French Polynesia',
        code: '+689',
        value: '689'
    },
    {
        id: 'GA',
        country: 'Gabon',
        code: '+241',
        value: '241'
    },
    {
        id: 'GM',
        country: 'Gambia',
        code: '+220',
        value: '220'
    },
    {
        id: 'GE',
        country: 'Georgia',
        code: '+995',
        value: '995'
    },
    {
        id: 'DE',
        country: 'Germany',
        code: '+49',
        value: '49'
    },
    {
        id: 'GH',
        country: 'Ghana',
        code: '+233',
        value: '233'
    },
    {
        id: 'GI',
        country: 'Gibraltar',
        code: '+350',
        value: '350'
    },
    {
        id: 'GR',
        country: 'Greece',
        code: '+30',
        value: '30'
    },
    {
        id: 'GL',
        country: 'Greenland',
        code: '+299',
        value: '299'
    },
    {
        id: 'GD',
        country: 'Grenada',
        code: '+1-473',
        value: '1-473'
    },
    {
        id: 'GU',
        country: 'Guam',
        code: '+1-671',
        value: '1-671'
    },
    {
        id: 'GT',
        country: 'Guatemala',
        code: '+502',
        value: '502'
    },
    {
        id: 'GG',
        country: 'Guernsey',
        code: '+44-1481',
        value: '44-1481'
    },
    {
        id: 'GN',
        country: 'Guinea',
        code: '+224',
        value: '224'
    },
    {
        id: 'GW',
        country: 'Guinea-Bissau',
        code: '+245',
        value: '245'
    },
    {
        id: 'GY',
        country: 'Guyana',
        code: '+592',
        value: '592'
    },
    {
        id: 'HT',
        country: 'Haiti',
        code: '+509',
        value: '509'
    },
    {
        id: 'HN',
        country: 'Honduras',
        code: '+504',
        value: '504'
    },
    {
        id: 'HK',
        country: 'Hong Kong',
        code: '+852',
        value: '852'
    },
    {
        id: 'HU',
        country: 'Hungary',
        code: '+36',
        value: '36'
    },
    {
        id: 'IS',
        country: 'Iceland',
        code: '+354',
        value: '354'
    },
    {
        id: 'IN',
        country: 'India',
        code: '+91',
        value: '91'
    },
    {
        id: 'ID',
        country: 'Indonesia',
        code: '+62',
        value: '62'
    },
    {
        id: 'IR',
        country: 'Iran',
        code: '+98',
        value: '98'
    },
    {
        id: 'IQ',
        country: 'Iraq',
        code: '+964',
        value: '964'
    },
    {
        id: 'IE',
        country: 'Ireland',
        code: '+353',
        value: '353'
    },
    {
        id: 'IM',
        country: 'Isle of Man',
        code: '+44-1624',
        value: '44-1624'
    },
    {
        id: 'IL',
        country: 'Israel',
        code: '+972',
        value: '972'
    },
    {
        id: 'IT',
        country: 'Italy',
        code: '+39',
        value: '39'
    },
    {
        id: 'CI',
        country: 'Ivory Coast',
        code: '+225',
        value: '225'
    },
    {
        id: 'JM',
        country: 'Jamaica',
        code: '+1-876',
        value: '1-876'
    },
    {
        id: 'JP',
        country: 'Japan',
        code: '+81',
        value: '81'
    },
    {
        id: 'JE',
        country: 'Jersey',
        code: '+44-1534',
        value: '44-1534'
    },
    {
        id: 'JO',
        country: 'Jordan',
        code: '+962',
        value: '962'
    },

    {
        id: 'KZ',
        country: 'Kazakhstan',
        code: '+7',
        value: '7'
    },
    {
        id: 'KE',
        country: 'Kenya',
        code: '+254',
        value: '254'
    },
    {
        id: 'KI',
        country: 'Kiribati',
        code: '+686',
        value: '686'
    },
    {
        id: 'XK',
        country: 'Kosovo',
        code: '+383',
        value: '383'
    },
    {
        id: 'KW',
        country: 'Kuwait',
        code: '+965',
        value: '965'
    },
    {
        id: 'KG',
        country: 'Kyrgyzstan',
        code: '+996',
        value: '996'
    },
    {
        id: 'LA',
        country: 'Laos',
        code: '+856',
        value: '856'
    },
    {
        id: 'LV',
        country: 'Latvia',
        code: '+371',
        value: '371'
    },
    {
        id: 'LB',
        country: 'Lebanon',
        code: '+961',
        value: '961'
    },
    {
        id: 'LS',
        country: 'Lesotho',
        code: '+266',
        value: '266'
    },
    {
        id: 'LR',
        country: 'Liberia',
        code: '+231',
        value: '231'
    },
    {
        id: 'LY',
        country: 'Libya',
        code: '+218',
        value: '218'
    },
    {
        id: 'LI',
        country: 'Liechtenstein',
        code: '+423',
        value: '423'
    },
    {
        id: 'LT',
        country: 'Lithuania',
        code: '+370',
        value: '370'
    },
    {
        id: 'LU',
        country: 'Luxembourg',
        code: '+352',
        value: '352'
    },
    {
        id: 'MO',
        country: 'Macau',
        code: '+853',
        value: '853'
    },
    {
        id: 'MK',
        country: 'Macedonia',
        code: '+389',
        value: '389'
    },
    {
        id: 'MG',
        country: 'Madagascar',
        code: '+261',
        value: '261'
    },
    {
        id: 'MW',
        country: 'Malawi',
        code: '+265',
        value: '265'
    },
    {
        id: 'MY',
        country: 'Malaysia',
        code: '+60',
        value: '60'
    },
    {
        id: 'MV',
        country: 'Maldives',
        code: '+960',
        value: '960'
    },
    {
        id: 'ML',
        country: 'Mali',
        code: '+223',
        value: '223'
    },
    {
        id: 'MT',
        country: 'Malta',
        code: '+356',
        value: '356'
    },
    {
        id: 'MH',
        country: 'Marshall Islands',
        code: '+692',
        value: '692'
    },
    {
        id: 'MR',
        country: 'Mauritania',
        code: '+222',
        value: '222'
    },
    {
        id: 'MU',
        country: 'Mauritius',
        code: '+230',
        value: '230'
    },
    {
        id: 'YT',
        country: 'Mayotte',
        code: '+262',
        value: '262'
    },
    {
        id: 'MX',
        country: 'Mexico',
        code: '+52',
        value: '52'
    },
    {
        id: 'FM',
        country: 'Micronesia',
        code: '+691',
        value: '691'
    },
    {
        id: 'MD',
        country: 'Moldova',
        code: '+373',
        value: '373'
    },
    {
        id: 'MC',
        country: 'Monaco',
        code: '+377',
        value: '377'
    },
    {
        id: 'MN',
        country: 'Mongolia',
        code: '+976',
        value: '976'
    },
    {
        id: 'ME',
        country: 'Montenegro',
        code: '+382',
        value: '382'
    },
    {
        id: 'MS',
        country: 'Montserrat',
        code: '+1-664',
        value: '1-664'
    },
    {
        id: 'MA',
        country: 'Morocco',
        code: '+212',
        value: '212'
    },
    {
        id: 'MZ',
        country: 'Mozambique',
        code: '+258',
        value: '258'
    },
    {
        id: 'NA',
        country: 'Namibia',
        code: '+264',
        value: '264'
    },
    {
        id: 'NR',
        country: 'Nauru',
        code: '+674',
        value: '674'
    },
    {
        id: 'NP',
        country: 'Nepal',
        code: '+977',
        value: '977'
    },
    {
        id: 'NL',
        country: 'Netherlands',
        code: '+31',
        value: '31'
    },
    {
        id: 'AN',
        country: 'Netherlands Antilles',
        code: '+599',
        value: '599'
    },
    {
        id: 'NC',
        country: 'New Caledonia',
        code: '+687',
        value: '687'
    },
    {
        id: 'NZ',
        country: 'New Zealand',
        code: '+64',
        value: '64'
    },
    {
        id: 'NI',
        country: 'Nicaragua',
        code: '+505',
        value: '505'
    },
    {
        id: 'NE',
        country: 'Niger',
        code: '+227',
        value: '227'
    },
    {
        id: 'NG',
        country: 'Nigeria',
        code: '+234',
        value: '234'
    },
    {
        id: 'NU',
        country: 'Niue',
        code: '+683',
        value: '683'
    },
    {
        id: 'MP',
        country: 'Northern Mariana Islands',
        code: '+1-670',
        value: '1-670'
    },
    {
        id: 'KP',
        country: 'North Korea',
        code: '+850',
        value: '850'
    },
    {
        id: 'NO',
        country: 'Norway',
        code: '+47',
        value: '47'
    },
    {
        id: 'OM',
        country: 'Oman',
        code: '+968',
        value: '968'
    },
    {
        id: 'PK',
        country: 'Pakistan',
        code: '+92',
        value: '92'
    },
    {
        id: 'PW',
        country: 'Palau',
        code: '+680',
        value: '680'
    },
    {
        id: 'PS',
        country: 'Palestine',
        code: '+970',
        value: '970'
    },
    {
        id: 'PA',
        country: 'Panama',
        code: '+507',
        value: '507'
    },
    {
        id: 'PG',
        country: 'Papua New Guinea',
        code: '+675',
        value: '675'
    },
    {
        id: 'PY',
        country: 'Paraguay',
        code: '+595',
        value: '595'
    },
    {
        id: 'PE',
        country: 'Peru',
        code: '+51',
        value: '51'
    },
    {
        id: 'PH',
        country: 'Philippines',
        code: '+63',
        value: '63'
    },
    {
        id: 'PN',
        country: 'Pitcairn',
        code: '+64',
        value: '64'
    },
    {
        id: 'PL',
        country: 'Poland',
        code: '+48',
        value: '48'
    },
    {
        id: 'PT',
        country: 'Portugal',
        code: '+351',
        value: '351'
    },
    {
        id: 'PR',
        country: 'Puerto Rico',
        code: '+1-787, 1-939',
        value: '1-787, 1-939'
    },
    {
        id: 'QA',
        country: 'Qatar',
        code: '+974',
        value: '974'
    },
    {
        id: 'RE',
        country: 'Reunion',
        code: '+262',
        value: '262'
    },
    {
        id: 'RO',
        country: 'Romania',
        code: '+40',
        value: '40'
    },
    {
        id: 'RU',
        country: 'Russia',
        code: '+7',
        value: '7'
    },
    {
        id: 'ST',
        country: 'Sao Tome and Principe',
        code: '+239',
        value: '239'
    },
    {
        id: 'SA',
        country: 'Saudi Arabia',
        code: '+966',
        value: '966'
    },
    {
        id: 'SN',
        country: 'Senegal',
        code: '+221',
        value: '221'
    },
    {
        id: 'RS',
        country: 'Serbia',
        code: '+381',
        value: '381'
    },
    {
        id: 'SC',
        country: 'Seychelles',
        code: '+248',
        value: '248'
    },
    {
        id: 'SL',
        country: 'Sierra Leone',
        code: '+232',
        value: '232'
    },
    {
        id: 'SG',
        country: 'Singapore',
        code: '+65',
        value: '65'
    },
    {
        id: 'SX',
        country: 'Sint Maarten',
        code: '+1-721',
        value: '1-721'
    },
    {
        id: 'SK',
        country: 'Slovakia',
        code: '+421',
        value: '421'
    },
    {
        id: 'SI',
        country: 'Slovenia',
        code: '+386',
        value: '386'
    },
    {
        id: 'SB',
        country: 'Solomon Islands',
        code: '+677',
        value: '677'
    },
    {
        id: 'SO',
        country: 'Somalia',
        code: '+252',
        value: '252'
    },
    {
        id: 'ZA',
        country: 'South Africa',
        code: '+27',
        value: '27'
    },
    {
        id: 'KR',
        country: 'South Korea',
        code: '+82',
        value: '82'
    },
    {
        id: 'SS',
        country: 'South Sudan',
        code: '+211',
        value: '211'
    },
    {
        id: 'ES',
        country: 'Spain',
        code: '+34',
        value: '34'
    },
    {
        id: 'LK',
        country: 'Sri Lanka',
        code: '+94',
        value: '94'
    },
    {
        id: 'SH',
        country: 'Saint Helena',
        code: '+290',
        value: '290'
    },
    {
        id: 'KN',
        country: 'Saint Kitts and Nevis',
        code: '+1-869',
        value: '1-869'
    },
    {
        id: 'LC',
        country: 'Saint Lucia',
        code: '+1-758',
        value: '1-758'
    },
    {
        id: 'MF',
        country: 'Saint Martin',
        code: '+590',
        value: '590'
    },
    {
        id: 'PM',
        country: 'Saint Pierre and Miquelon',
        code: '+508',
        value: '508'
    },
    {
        id: 'VC',
        country: 'Saint Vincent and the Grenadines',
        code: '+1-784',
        value: '1-784'
    },
    {
        id: 'SD',
        country: 'Sudan',
        code: '+249',
        value: '249'
    },
    {
        id: 'SR',
        country: 'Suriname',
        code: '+597',
        value: '597'
    },
    {
        id: 'SJ',
        country: 'Svalbard and Jan Mayen',
        code: '+47',
        value: '47'
    },
    {
        id: 'SZ',
        country: 'Swaziland',
        code: '+268',
        value: '268'
    },
    {
        id: 'SE',
        country: 'Sweden',
        code: '+46',
        value: '46'
    },
    {
        id: 'CH',
        country: 'Switzerland',
        code: '+41',
        value: '41'
    },
    {
        id: 'SY',
        country: 'Syria',
        code: '+963',
        value: '963'
    },
    {
        id: 'TW',
        country: 'Taiwan',
        code: '+886',
        value: '886'
    },
    {
        id: 'TJ',
        country: 'Tajikistan',
        code: '+992',
        value: '992'
    },
    {
        id: 'TZ',
        country: 'Tanzania',
        code: '+255',
        value: '255'
    },
    {
        id: 'TG',
        country: 'Togo',
        code: '+228',
        value: '228'
    },
    {
        id: 'TK',
        country: 'Tokelau',
        code: '+690',
        value: '690'
    },
    {
        id: 'TO',
        country: 'Tonga',
        code: '+676',
        value: '676'
    },
    {
        id: 'TT',
        country: 'Trinidad and Tobago',
        code: '+1-868',
        value: '1-868'
    },
    {
        id: 'TN',
        country: 'Tunisia',
        code: '+216',
        value: '216'
    },
    {
        id: 'TR',
        country: 'Turkey',
        code: '+90',
        value: '90'
    },
    {
        id: 'TM',
        country: 'Turkmenistan',
        code: '+993',
        value: '993'
    },
    {
        id: 'TC',
        country: 'Turks and Caicos Islands',
        code: '+1-649',
        value: '1-649'
    },
    {
        id: 'TV',
        country: 'Tuvalu',
        code: '+688',
        value: '688'
    },
    {
        id: 'AE',
        country: 'United Arab Emirates',
        code: '+971',
        value: '971'
    },
    {
        id: 'UG',
        country: 'Uganda',
        code: '+256',
        value: '256'
    },
    {
        id: 'GB',
        country: 'United Kingdom',
        code: '+44',
        value: '44'
    },
    {
        id: 'UA',
        country: 'Ukraine',
        code: '+380',
        value: '380'
    },
    {
        id: 'UY',
        country: 'Uruguay',
        code: '+598',
        value: '598'
    },
    {
        id: 'UZ',
        country: 'Uzbekistan',
        code: '+998',
        value: '998'
    },
    {
        id: 'VU',
        country: 'Vanuatu',
        code: '+678',
        value: '678'
    },
    {
        id: 'VA',
        country: 'Vatican',
        code: '+379',
        value: '379'
    },
    {
        id: 'VE',
        country: 'Venezuela',
        code: '+58',
        value: '58'
    },
    {
        id: 'VN',
        country: 'Vietnam',
        code: '+84',
        value: '84'
    },
    {
        id: 'VI',
        country: 'U.S. Virgin Islands',
        code: '+1-340',
        value: '1-340'
    },
    {
        id: 'WF',
        country: 'Wallis and Futuna',
        code: '+681',
        value: '681'
    },
    {
        id: 'EH',
        country: 'Western Sahara',
        code: '+212',
        value: '212'
    },
    {
        id: 'YE',
        country: 'Yemen',
        code: '+967',
        value: '967'
    },
    {
        id: 'ZM',
        country: 'Zambia',
        code: '+260',
        value: '260'
    },
    {
        id: 'ZW',
        country: 'Zimbabwe',
        code: '+263',
        value: '263'
    }

]