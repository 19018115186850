/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    extend: {
      aspectRatio: {
        '4/3': '4 / 3',
        '3/2': '3 / 2'
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic':
          'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      // fontFamily: {
      //   // sans: ['var(--font-inter)'],
      //   mono: ['var(--font-roboto)'],
      // },
      fontFamily: {
        sans: ['var(--font-prompt)'],
        mono: ['var(--font-prompt)'],
        default: ['var(--font-prompt)'],
        'root': ['var(--font-prompt)'],
        'body': ['var(--font-prompt)'],
      },
      colors: {
        'maincolor': '#16263f',
        'maincolortrans': '#2331424d',
        // 'secondary': '#015B97',
        'secondary': '#05294d',
        'third': '#ba8800',
        'forth': '#e3e3e3',
        'light': 'white',
        'navlight': '#D3D3D3',
        'mainhover': '#0a58ca'

      }
    },
  },
  plugins: [],
}
