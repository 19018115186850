export const preferredContact = {
    "en": [
        {
            name: 'Phone',
            value: 'phone'
        },
        {
            name: 'Email',
            value: 'email'
        },
        {
            name: 'LINE',
            value: 'line'
        },
        {
            name: 'WhatsApp',
            value: 'whatsapp'
        },
        {
            name: 'Wechat - 微信',
            value: 'wechat'
        },
        {
            name: 'Viber',
            value: 'viber'
        }
    ],
    "th": [
        {
            name: 'เบอร์โทร',
            value: 'phone'
        },
        {
            name: 'อีเมลล์',
            value: 'email'
        },
        {
            name: 'ไลน์',
            value: 'line'
        },
        {
            name: 'WhatsApp',
            value: 'whatsapp'
        },
        {
            name: 'วีแชท',
            value: 'wechat'
        },
        {
            name: 'Viber',
            value: 'viber'
        }
    ],
    "cn": [
        {
            name: '手机号码',
            value: 'phone'
        },
        {
            name: '微信',
            value: 'wechat'
        },
        {
            name: 'LINE',
            value: 'line'
        },
        {
            name: 'WhatsApp',
            value: 'whatsapp'
        },
        {
            name: 'Viber',
            value: 'viber'
        },
        {
            name: '电子邮箱',
            value: 'email'
        },
    ]
}